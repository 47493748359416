import React from "react";
import SubscribeTitleComponent from "../SubscribeTitleComponent/SubscribeTitleComponent";
import { useTranslation } from "react-i18next";
import BoxIcon from "../Icons/BoxIcon";
import { MdMonetizationOn } from "react-icons/md";
import "./SubscribeCargoComponent.scss";
import BoxesIcon from "./../Icons/BoxesIcon";
import KGIcon from "./../Icons/KGIcon";
import { MdGTranslate, MdInsertDriveFile } from "react-icons/md";
import { Link } from "react-router-dom";
import { useMoney } from "../../../helper/useMoney";

const SubscribeCargoComponent = ({ order }) => {
  const { t } = useTranslation();

  const cargo = order?.cargos?.[0];
  const cargoPrice = useMoney(cargo?.price, cargo?.price_currency?.symbol, 0);
  const otherDocuments = order?.attachments?.filter((document) => document.type?.name === "Other");

  return (
    <div id='SubscribeCargoComponent'>
      <div className='subscribeCargoCard'>
        <div className='CardHeader'>
          <SubscribeTitleComponent title={t("subscribers.details.Cargo")} icon={<BoxIcon />} />
          {cargo?.price && (
            <div className='money'>
              <div className='icon'>{<MdMonetizationOn />}</div>
              <h4>{cargoPrice}</h4>
            </div>
          )}
        </div>
        <div className='CardBody'>
          <div className='cargoInfo-1'>
            {cargo?.type?.name && (
              <div className='CInfo'>
                <BoxesIcon />
                <p>{cargo?.type?.name}</p>
              </div>
            )}
            {cargo?.weight && (
              <div className='CInfo'>
                <KGIcon />
                <p>
                  {cargo?.weight} <span>{t("subscribers.details.KG")}</span>
                </p>
              </div>
            )}
          </div>
          {cargo?.cargo_ready_date && (
            <div className='cargoInfo-2 RDate'>
              <p>{t("subscribers.details.Ready_date")}</p>
              <h3>{cargo?.cargo_ready_date}</h3>
            </div>
          )}
          {(otherDocuments?.length > 0 || order?.comment) && (
            <div className='cargoInfo-3 AddInfo'>
              <div className='Addinfo-top'>
                <h2>{t("subscribers.details.Additional_info")}</h2>
                {/* <div className='translateGoogleApi'>
                <Link to={"#"}>
                  <span>
                    <MdGTranslate />
                  </span>
                  {t("subscribers.details.Show_original")}
                </Link>
              </div> */}
              </div>
              {order?.comment && <div className='text'>{order?.comment}</div>}
            </div>
          )}

          {otherDocuments?.length > 0 && (
            <div className='cargoInfo-4 AFiles'>
              <h3>{t("subscribers.details.Attached_documents")}</h3>
              <div className='documentsList'>
                {otherDocuments?.map((document, index) => (
                  <Link key={index} to={document?.link} target='_blank'>
                    <span>
                      <MdInsertDriveFile />
                    </span>
                    <p>{document?.name}</p>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscribeCargoComponent;
