import React from "react";
import "./FiltersCheckboxMultiselect.scss";

import { useSearchParams } from "react-router-dom";
import Select, { components } from "react-select";

import { FaChevronDown } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import useQuerySerialization from "../../../helper/useQuerySerialization";

const FiltersCheckboxMultiselect = ({
  selected,
  setSelected,
  options,
  placeholder,
  searchParamName,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { serializeArray } = useQuerySerialization();

  const handleChange = (selected) => {
    if (setSelected) {
      setSelected(selected);
      searchParams.set(searchParamName, serializeArray(selected));
      setSearchParams(searchParams);
    }
  };

  const Control = (props) => {
    return (
      <components.Control
        className={`control ${selected?.length > 0 ? "selected" : ""}`}
        {...props}
      />
    );
  };

  const MultiValue = (props) => {
    const { data, selectProps } = props;
    const selected = selectProps?.value;

    if (selected.length === 1) {
      return <span style={{ fontSize: "13px", fontWeight: 600 }}>{data?.label}</span>;
    }

    if (selected?.length > 1 && selected?.[0]?.value?.id === data?.value?.id) {
      return (
        <span
          style={{
            fontSize: "13px",
            fontWeight: 600,
            whiteSpace: "nowrap",
          }}
        >
          {selected?.[0]?.label} +{selected?.length - 1}
        </span>
      );
    }

    return null;
  };

  const customOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;

    return (
      <div ref={innerRef} {...innerProps} className='option'>
        <label style={{ position: "relative", display: "flex", alignItems: "center" }}>
          <input
            type='checkbox'
            checked={isSelected}
            readOnly
            className='optionCheckbox'
            style={{
              backgroundColor: isSelected ? "#00b56c" : "white",
            }}
          />
          {isSelected && <span className='check'>✓</span>}
        </label>

        <span style={{ marginLeft: "8px" }}>{data?.label}</span>
        <span style={{ fontSize: "13px", marginLeft: "auto", color: "#2e5aac" }}>
          {data?.value?.count}
        </span>
      </div>
    );
  };

  return (
    <Select
      id={"FiltersCheckboxMultiselect"}
      placeholder={placeholder}
      options={options}
      isMulti
      value={selected}
      onChange={handleChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      getOptionValue={(option) => option?.value?.id}
      styles={{
        control: (provided) => ({
          ...provided,
          boxShadow: "none",
          minHeight: "13px",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          transform: "none",
          transition: "none",
        }),
        input: (provided) => ({
          ...provided,
          fontSize: "13px",
          margin: 0,
          padding: 0,
          maxHeight: "13px",
          "&input": {
            maxHeight: "13px",
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: "13px",
          fontWeight: 600,
          color: "#000000",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          padding: 0,
          maxHeight: "13px",
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: 0,
        }),
      }}
      components={{
        Option: customOption,
        MultiValue,
        Control,
        IndicatorSeparator: null,
        DropdownIndicator:
          selected?.length > 0 ? null : () => <FaChevronDown style={{ fontSize: "13px" }} />,
        ClearIndicator: (props) => (
          <components.ClearIndicator {...props}>
            <MdCancel style={{ cursor: "pointer" }} />
          </components.ClearIndicator>
        ),
      }}
    />
  );
};

export default FiltersCheckboxMultiselect;
