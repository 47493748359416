import React from "react";
import "./SubscribersTabSwitcher.scss";

import { useSearchParams } from "react-router-dom";

const SubscribersTabSwitcher = ({ badge, activeTab, setActiveTab }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  return (
    <div id='SubscribersTabSwitcher'>
      <ul className='list'>
        <li
          className={activeTab === "loads" ? "active" : "false"}
          onClick={() => handleChangeTab("loads")}
        >
          <p>All loads</p>
          <div className='line' />
        </li>
        <li
          className={activeTab === "bids" ? "active" : "false"}
          onClick={() => handleChangeTab("bids")}
        >
          <div className='titleContainer'>
            <p id={badge && "BadgePadding"}>My bids</p>
            {badge > 0 && (
              <div className='PageSwitcherBadge'>
                <h3>{badge > 99 ? "99+" : badge}</h3>
              </div>
            )}
          </div>
          <div className='line' />
        </li>
      </ul>
    </div>
  );
};

export default SubscribersTabSwitcher;
