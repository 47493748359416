import React, { forwardRef, useEffect, useState } from "react";
import "./SubscribersFiltersMobileFiltersModal.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FaFilter } from "react-icons/fa";
import { MdCalendarToday, MdEdit, MdLocationOn, MdLocationSearching } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { FiX } from "react-icons/fi";

import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import en from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

import DOverlay from "../../../../componentsDashboard/Modal/DOverlay/DOverlay";
import DTextButton from "../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import DButton from "../../../../componentsDashboard/Form/DButtons/DButton/DButton";
import DIconButton from "../../../../componentsDashboard/Form/DButtons/DIconButton/DIconButton";
import FilterPlainToggleButton from "../../Components/FilterPlainToggleButton/FilterPlainToggleButton";
import ToggleButton from "./ToggleButton/ToggleButton";
import DInputs from "../../../../componentsDashboard/Form/DInputs/DInputs";

const SubscribersFiltersMobileFiltersModal = ({
  isOpen,
  isClose,
  onClear,
  pickupDropoffInGeorgia,
  setPickupDropoffInGeorgia,
  pickupNext3Days,
  setPickupNext3Days,
  selectedRoutes,
  setSelectedRoutes,
  routesOptions,
  selectedTrailers,
  setSelectedTrailers,
  trailersOptions,
  pickupCountry,
  setPickupCountry,
  dropoffCountry,
  setDropoffCountry,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const { t } = useTranslation();

  const { ISO } = useSelector((state) => state.lang);

  const [openPreferencesEditModal, setOpenPreferencesEditModal] = useState(false);
  const [mobilePickupDropoffInGeorgia, setMobilePickupDropoffInGeorgia] =
    useState(pickupDropoffInGeorgia);
  const [mobilePickupNext3Days, setMobilePickupNext3Days] = useState(pickupNext3Days);
  const [mobileSelectedRoutes, setMobileSelectedRoutes] = useState(selectedRoutes);
  const [mobilePickupCountry, setMobilePickupCountry] = useState(pickupCountry);
  const [mobileDropoffCountry, setMobileDropoffCountry] = useState(dropoffCountry);
  const [openPickupDropoffCountry, setOpenPickupDropoffCountry] = useState(
    pickupCountry || dropoffCountry ? true : false
  );
  const [mobileStartDate, setMobileStartDate] = useState(startDate);
  const [mobileEndDate, setMobileEndDate] = useState(endDate);

  const [mobileSelectedTrailers, setMobileSelectedTrailers] = useState(selectedTrailers);

  const handleFilter = () => {
    setPickupDropoffInGeorgia(mobilePickupDropoffInGeorgia);
    setPickupNext3Days(mobilePickupNext3Days);
    setSelectedRoutes(mobileSelectedRoutes);
    setPickupCountry(mobilePickupCountry);
    setDropoffCountry(mobileDropoffCountry);
    setStartDate(mobileStartDate);
    setEndDate(mobileEndDate);
    setSelectedTrailers(mobileSelectedTrailers);
    isClose();
  };

  const handleToggleSelect = (item, mobileState, setMobileState) => {
    const id = item?.value?.id;

    const selecteditem = mobileState?.find((selected) => selected?.value?.id === id);
    return (
      <ToggleButton
        key={id}
        text={item?.label}
        isActive={selecteditem ? true : false}
        handleClick={() => {
          if (!selecteditem) {
            setMobileState([...mobileState, item]);
          } else {
            setMobileState(mobileState.filter((selected) => selected?.value?.id !== id));
          }
        }}
      />
    );
  };

  const DDatepickerButton = forwardRef(({ value, onClick }, ref) => (
    <div id='DDatepickerButton' onClick={onClick} ref={ref}>
      <div className='text'>
        {value ? (
          <p className='valuetext'>{value}</p>
        ) : (
          <p className='placeholder'>{"Start date - End date"}</p>
        )}
      </div>
      <div className='icon'>
        <MdCalendarToday />
      </div>
    </div>
  ));

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setMobileStartDate(start);
    setMobileEndDate(end);
  };

  useEffect(() => {
    if (ISO === "en") {
      registerLocale("en", en);
    } else if (ISO === "ka") {
      registerLocale("ka", ka);
    } else if (ISO === "ru") {
      registerLocale("ru", ru);
    }
  }, [ISO]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='SubscribersFiltersMobileFiltersModal'>
        <div
          className='header'
          style={{
            borderBottom: `2px solid #1A2023`,
            backgroundColor: `#F4F6F9`,
          }}
        >
          <div className='title'>
            <div className='titleIcon'>
              <FaFilter />
            </div>
            <h2 style={{ color: `#1A2023` }}>Filters</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='content'>
          <div className='sectionContainer'>
            <div className='title'>
              <h3>Preferences</h3>
              <DIconButton icon={<MdEdit />} onClick={() => setOpenPreferencesEditModal(true)} />
            </div>
            <div className='filterContent'>
              <FilterPlainToggleButton
                text={"Pickup/Drop off in Georgia"}
                isActive={mobilePickupDropoffInGeorgia}
                setIsActive={setMobilePickupDropoffInGeorgia}
              />
            </div>
          </div>
          <div className='sectionContainer'>
            <div className='title'>
              <h3>Route</h3>
            </div>
            <div className='filterContent'>
              {routesOptions.map((route) =>
                handleToggleSelect(route, mobileSelectedRoutes, setMobileSelectedRoutes)
              )}
              <div
                className='customRoute'
                onClick={() => setOpenPickupDropoffCountry(!openPickupDropoffCountry)}
              >
                <p>Custom Route</p>
                <div className='icon'>
                  <IoIosArrowDown />
                </div>
              </div>
            </div>
          </div>
          {openPickupDropoffCountry && (
            <div className='sectionContainer'>
              <div className='title'>
                <h3>Custom route</h3>
              </div>
              <div className='filterContent'>
                <DInputs
                  icon={<MdLocationSearching />}
                  placeholder={"Pickup country"}
                  value={mobilePickupCountry}
                  setValue={setMobilePickupCountry}
                />
                <DInputs
                  icon={<MdLocationOn />}
                  placeholder={"Drop off country"}
                  value={mobileDropoffCountry}
                  setValue={setMobileDropoffCountry}
                />
              </div>
            </div>
          )}
          <div className='sectionContainer'>
            <div className='title'>
              <h3>Trailer type</h3>
            </div>
            <div className='filterContent'>
              {trailersOptions.map((trailer) =>
                handleToggleSelect(trailer, mobileSelectedTrailers, setMobileSelectedTrailers)
              )}
            </div>
          </div>
          <div className='sectionContainer'>
            <div className='title'>
              <h3>Pick-up Date</h3>
            </div>
            <div className='filterContent'>
              <DatePicker
                selected={mobileStartDate}
                onChange={onDateChange}
                startDate={mobileStartDate}
                endDate={mobileEndDate}
                selectsRange
                placeholderText='Select date range'
                customInput={<DDatepickerButton />}
              />
              <FilterPlainToggleButton
                text={"Pick up next 3 days"}
                isActive={mobilePickupNext3Days}
                setIsActive={setMobilePickupNext3Days}
              />
            </div>
          </div>
        </div>

        <div className='footer'>
          <DTextButton
            title={t("Clear")}
            onClick={() => {
              onClear();
              isClose();
            }}
            info
          />
          <DButton title={t("Filter")} onClick={handleFilter} />
        </div>
      </div>
    </DOverlay>
  );
};

export default SubscribersFiltersMobileFiltersModal;
