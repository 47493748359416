import React from "react";
import "./LoadBoardsWeightField.scss";

const LoadBoardsWeightField = ({ item }) => {
  return (
    <td id='LoadBoardsWeightField'>
      <div className='LoadBoardsWeightFieldcontent'>
        <div className='weight'>{item?.weight} KG</div>
        <p>{item?.cargos?.[0]?.type?.name || ""}</p>
      </div>
    </td>
  );
};

export default LoadBoardsWeightField;
