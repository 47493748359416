import React from "react";

const ShareOrders = () => {
  console.log("ShareOrders");
  return (
    <div>
      <h3>sddsd</h3>
      <h3>sddsd</h3>

      <h3>sddsd</h3>
      <h3>sddsd</h3>
      <h3>sddsd</h3>
      <div className='headImage'>
        <div className='imgBox'>
          <div
            className='img'
            style={{
              backgroundImage: `url(${`/assets/images/subscribers/Inbox-cleanup-amico-1.svg`})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ShareOrders;
