import React, { Fragment, useEffect, useState } from "react";
import "./UnsubscribeComponent.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { MdInfoOutline } from "react-icons/md";

import UnsubscribeButton from "../UnsubscribeButton/UnsubscribeButton";
import {
  resetResubscribe,
  resetUnsubscribe,
  resubscribe,
  unsubscribe,
} from "../../../store/subscribers/subscribersSlice";

import DModal from "../../../componentsDashboard/Modal/DModal/DModal";

const UnsubscribeComponent = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [unModal, setUnModal] = useState(false);
  const [showResubscribe, setShowResubscribe] = useState(false);

  const { unsubscribeResult, unsubscribeLoading, resubscribeResult, resubscribeLoading } =
    useSelector((state) => state.subscribers);

  const unsubscribeHandler = () => {
    dispatch(unsubscribe(token));
  };

  useEffect(() => {
    if (unsubscribeResult) {
      setUnModal(false);
      setShowResubscribe(true);
    }
    return () => {
      dispatch(resetUnsubscribe());
    };
  }, [dispatch, unsubscribeResult]);

  const resubscribeHandler = () => {
    dispatch(resubscribe(token));
  };

  useEffect(() => {
    if (resubscribeResult) {
      navigate(`/subscribers/${token}`);
    }
    return () => {
      dispatch(resetResubscribe());
    };
  }, [dispatch, resubscribeResult, token, navigate]);

  return (
    <Fragment>
      <div id='UnsubscribeComponent'>
        <div className='unsubscribeContainer'>
          <div className='headImage'>
            <div className='imgBox'>
              <div
                className='img'
                style={{
                  backgroundImage: `url(${`/assets/images/subscribers/Inbox-cleanup-amico-1.svg`})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
          <div className='bodyContent'>
            {!showResubscribe && (
              <div className='unsubscribeContent'>
                <h2>{t("subscribers.unsubscribe.Do_you_really_want_to_unsubscribe")}</h2>
                {/* <div className='warnText'>
                <div className='icon'>
                  <MdInfoOutline />
                </div>
                <p>
                  Before you go, you can customise your notifications instead of fully
                  unsubscribing:
                </p>
              </div>
              <div className='unsubscribeSelectsBox'>selects box .......////////////////////</div> */}
                <div className='textItalicConfirm'>
                  If you still want to unsubscribe completely, confirm below:
                </div>
                <div className='unShipmentBox'>
                  <h2>If you unsubscribe, you will no longer receive:</h2>
                  <div className='ship-1-box'>
                    <div className='icon'>🚛</div>
                    <p>Daily Shipment Opportunities</p>
                  </div>
                  <div className='ship-1-box'>
                    <div className='icon'>📍</div>
                    <p>Real-Time Shipment Alerts</p>
                  </div>
                </div>
                <div className='unsubscribeButton'>
                  <UnsubscribeButton
                    classButton={"unsBtn"}
                    title={t("subscribers.unsubscribe.Unsubscribe_title")}
                    click={() => setUnModal(true)}
                    loader={unsubscribeLoading}
                    color={"#DA1414"}
                  />
                </div>
              </div>
            )}
            {showResubscribe && (
              <div className='reSubscribeContent'>
                <h2>{t("subscribers.unsubscribe.You_Unsubscribed")}</h2>
                <div className='text'>
                  {t(
                    "subscribers.unsubscribe.You have successfully unsubscribed from our shipment notifications"
                  )}
                </div>
                <div className='unsubscribeButton'>
                  <UnsubscribeButton
                    classButton={"reSubBtn"}
                    title={t("subscribers.unsubscribe.Re_Subscribe_Now_title")}
                    click={() => resubscribeHandler()}
                    loader={resubscribeLoading}
                    color={"#1A2023"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {unModal && (
        <DModal
          isOpen={unModal}
          isClose={() => setUnModal(false)}
          title={t("subscribers.details.unsubscribe")}
          submitText={t("subscribers.details.accept")}
          status='danger'
          onClick={() => {
            unsubscribeHandler();
          }}
          loading={unsubscribeLoading}
        >
          <div id='unsubscribeModalBox'>
            <div className='unsubscribeModalText'>
              <h2>{t("subscribers.details.Unsubscribe")}</h2>
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default UnsubscribeComponent;
