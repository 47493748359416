import React from "react";

import { useTranslation } from "react-i18next";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "./BackToComponent.scss";

const BackToComponent = ({ title, alt, icon, addNumbers }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const submitHandler = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(alt ? alt : "/");
    }
  };

  return (
    <div id='BackToComponent'>
      <div className='titleSubmit' onClick={() => submitHandler()}>
        <div className='icon'>
          <IoIosArrowBack style={{ color: "#4F575B" }} />
        </div>
        <p>{title ? title : `${t("tms.forms.buttons.Back")}`}</p>
      </div>
      <div className='line-01'></div>
      <div className='IdNumberBox'>
        <div className='icon'>{icon}</div>
        <p>{addNumbers}</p>
      </div>
    </div>
  );
};

export default BackToComponent;
