import React from "react";
import "./SubscribeTransportComponent.scss";
import SubscribeTitleComponent from "../SubscribeTitleComponent/SubscribeTitleComponent";
import { useTranslation } from "react-i18next";
import TrailerIcon from "../Icons/TrailerIcon";

const SubscribeTransportComponent = ({ order }) => {
  const { t } = useTranslation();
  const cargo = order?.cargos?.[0];

  return (
    <div id='SubscribeTransportComponent'>
      <div className='subscribeTransportCard'>
        <div className='CardHeader'>
          <SubscribeTitleComponent
            title={t("subscribers.details.Trailer")}
            icon={<TrailerIcon />}
          />
        </div>
        <div className='CardBody'>
          <div className='transportationContainer'>
            <h2>{t("subscribers.details.Transportation_request")}</h2>
            <div
              className='trackImage'
              style={{
                backgroundImage: `url(${`/assets/images/subscribers/track.svg`})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "170px",
                height: "63px",
              }}
            ></div>
            <div className='TrackDataType'>
              {cargo?.trailerType?.name && (
                <div className='TrItem'>
                  <p>{t("subscribers.details.Trailer_Type")}</p>
                  <h3>{cargo?.trailerType?.name}</h3>
                </div>
              )}
              {cargo?.trailersize?.label && (
                <div className='TrItem'>
                  <p>{t("subscribers.details.Size")}</p>
                  <h3>{cargo?.trailersize?.label}</h3>
                </div>
              )}
              {cargo?.temperature && (
                <div className='TrItem'>
                  <p>{t("subscribers.details.Temperature")}</p>
                  <h3>{cargo?.temperature}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeTransportComponent;
