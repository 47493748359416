import React, { Fragment, useEffect } from "react";
import "./SubscribersLayout.scss";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  getPreferences,
  getProfile,
  getRoutes,
  getTrailerTypes,
} from "../../../store/subscribers/subscribersSlice";

import SubscribersHeader from "../../../componentsSubscribers/SubscribersHeader/SubscribersHeader";

const SubscribersLayout = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  const { makeBidResult, getTrailerTypesResult, getRoutesResult } = useSelector(
    (state) => state.subscribers
  );

  useEffect(() => {
    if (token) {
      dispatch(getPreferences(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      dispatch(getProfile(token));
    }
  }, [dispatch, token, makeBidResult]);

  useEffect(() => {
    if (!getTrailerTypesResult) {
      dispatch(getTrailerTypes());
    }
    if (!getRoutesResult) {
      dispatch(getRoutes());
    }
  }, [dispatch, getRoutesResult, getTrailerTypesResult]);

  return (
    <Fragment>
      <SubscribersHeader token={token} />
      <div id='SubscribersLayout'>
        <Outlet />
      </div>
    </Fragment>
  );
};

export default SubscribersLayout;
