import React, { Fragment, useState } from "react";
import "./LoadBoardsActionField.scss";

import { MdEdit } from "react-icons/md";

import { useMoney } from "../../../../../helper/useMoney";

import DButton from "../../../DButtons/DButton/DButton";
import DTextButton from "../../../DButtons/DTextButton/DTextButton";
import SubscribersMakeBidModal from "../../../../../componentsSubscribers/SubscribersOrdersList/Modals/SubscribersMakeBidModal/SubscribersMakeBidModal";
import { useParams } from "react-router-dom";

const LoadBoardsActionField = ({ item }) => {
  const { token } = useParams();

  const [openMakeBidModal, setOpenMakeBidModal] = useState(false);
  const [openEditMakeBidModal, setOpenEditMakeBidModal] = useState(false);

  const formattedBid = useMoney(item?.bid?.amount, item?.bid?.currency?.symbol);

  return (
    <Fragment>
      <td id='LoadBoardsActionField'>
        <div className='LoadBoardsActionFieldcontent'>
          {item?.bid ? (
            <div className='bidContainer'>
              <p>
                You offered: <span>{formattedBid}</span>
              </p>
              <DTextButton title={<MdEdit />} onClick={() => setOpenEditMakeBidModal(true)} />
            </div>
          ) : (
            <DButton title={"Make bid"} onClick={() => setOpenMakeBidModal(true)} />
          )}
        </div>
      </td>
      {openMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openMakeBidModal}
          isClose={() => setOpenMakeBidModal(false)}
          token={token}
          item={item}
        />
      )}
      {openEditMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openEditMakeBidModal}
          isClose={() => setOpenEditMakeBidModal(false)}
          token={token}
          item={item}
          edit
        />
      )}
    </Fragment>
  );
};

export default LoadBoardsActionField;
