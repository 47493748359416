import React, { Fragment, useEffect } from "react";
import "./SubscribersDetail.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { HiSparkles } from "react-icons/hi2";

import {
  getSubscribersLoadboardsOrder,
  resetGetSubscribersLoadboardsOrder,
} from "../../../store/subscribers/subscribersSlice";

import SubscribeRouteComponent from "../../../componentsSubscribers/SubscribersDetails/SubscribeRouteComponent/SubscribeRouteComponent";
import BackToComponent from "../../../componentsSubscribers/SubscribersDetails/BackToComponent/BackToComponent";
import SubscribeCargoComponent from "./../../../componentsSubscribers/SubscribersDetails/SubscribeCargoComponent/SubscribeCargoComponent";
import SubscribeTransportComponent from "../../../componentsSubscribers/SubscribersDetails/SubscribeTransportComponent/SubscribeTransportComponent";
import SubscribeBidReqComponent from "../../../componentsSubscribers/SubscribersDetails/SubscribeBidReqComponent/SubscribeBidReqComponent";

const SubscribersDetail = () => {
  const { t } = useTranslation();
  const { orderId, token } = useParams();
  const dispatch = useDispatch();

  const { getSubscribersLoadboardsOrderResult, makeBidResult } = useSelector(
    (state) => state.subscribers
  );

  useEffect(() => {
    if (orderId && token) {
      dispatch(
        getSubscribersLoadboardsOrder({
          orderId: orderId,
          signature: token,
        })
      );
    }
    return () => {
      dispatch(resetGetSubscribersLoadboardsOrder());
    };
  }, [dispatch, orderId, token, makeBidResult]);

  return (
    <Fragment>
      <div id='SubscribersDetail'>
        <div className='subscribeDetailMain wrapper'>
          <BackToComponent
            title={t("subscribers.details.Back_title")}
            alt={"route/asqwer/page"}
            addNumbers={getSubscribersLoadboardsOrderResult?.id}
            icon={getSubscribersLoadboardsOrderResult?.preference && <HiSparkles />}
          />
          <div className='subscribeDetailContent'>
            <div className='subscribeLeftComponents'>
              <SubscribeRouteComponent order={getSubscribersLoadboardsOrderResult} />
              <SubscribeCargoComponent order={getSubscribersLoadboardsOrderResult} />
            </div>
            <div className='subscribeRightComponents'>
              <SubscribeTransportComponent order={getSubscribersLoadboardsOrderResult} />
              <SubscribeBidReqComponent order={getSubscribersLoadboardsOrderResult} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscribersDetail;
