import React, { Fragment, useState, useEffect } from "react";
import "./VerificationModal.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DModal from "./../../../componentsDashboard/Modal/DModal/DModal";
import DPhoneInput from "./../../../componentsDashboard/Form/DPhoneInput/DPhoneInput";
import { IoMdArrowBack } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import OTP from "../../../componentsLanding/Form/OTP/OTP";
import SendAgain from "../../../componentsLanding/Auth&Password/Components/SendAgain/SendAgain";
import {
  resetSendOtp,
  sendOtp,
  resetVerify,
  verify,
} from "../../../store/subscribers/subscribersSlice";
const VerificationModal = ({ isOpen, isClose, setOpenMakeBidModal, setOpenVerifyModal }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();

  const {
    sendOtpResult,
    sendOtpLoading,
    sendOtpError,
    getProfileResult,
    verifyResult,
    verifyError,
  } = useSelector((state) => state.subscribers);

  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneIndex, setPhoneIndex] = useState(null);
  const [code, setCode] = useState("");
  const [sentCode, setSentCode] = useState(false);

  useEffect(() => {
    if (getPhoneIndexesResult && getPhoneIndexesResult) {
      const currentPhoneIndex = getPhoneIndexesResult?.find(
        (index) => index?.phone_index === getProfileResult?.registration_country?.phone_index
      );
      setPhoneIndex({
        value: currentPhoneIndex,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${currentPhoneIndex.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            ></div>
            <p>{currentPhoneIndex.phone_index}</p>
          </div>
        ),
      });
    }
  }, [getPhoneIndexesResult, getProfileResult?.registration_country?.phone_index]);

  const verifySubmitHandler = () => {
    const data = {
      signature: token,
      data: {
        phone: phoneNumber,
        phone_index_id: phoneIndex?.value?.id,
        type: "phone",
      },
    };
    dispatch(sendOtp(data));
  };

  useEffect(() => {
    if (sendOtpResult) {
      setSentCode(true);
    }
    return () => {
      dispatch(resetSendOtp());
    };
  }, [dispatch, sendOtpResult]);

  useEffect(() => {
    if (code?.length === 4) {
      const verifyData = {
        signature: token,
        data: {
          code: code,
          type: "phone",
        },
      };
      dispatch(verify(verifyData));
      setCode(null);
    }
  }, [dispatch, code, token]);

  useEffect(() => {
    if (verifyResult) {
      setSentCode(true);
      isClose(true);
      setOpenVerifyModal(false);
      setOpenMakeBidModal(true);
    }
    return () => {
      dispatch(resetVerify());
    };
  }, [dispatch, isClose, setOpenMakeBidModal, setOpenVerifyModal, verifyResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => {
          isClose(false);
          setSentCode(false);
        }}
        title={t("subscribers.details.Provide_phone_number")}
        submitText={t("subscribers.details.Verify")}
        status='info'
        onClick={() => {
          verifySubmitHandler();
        }}
        loading={sendOtpLoading}
        addClassname={`verificationModalHandle ${sentCode ? "ChangeSomeContent" : ""}`}
      >
        <div id='VerificationModal'>
          <div className='verificationBox'>
            <div className='verificationImage'>
              {sentCode && (
                <div className='BackVerification' onClick={() => setSentCode(false)}>
                  <div className='icon'>
                    <IoMdArrowBack />
                  </div>
                  <p>{t("subscribers.details.Back")}</p>
                </div>
              )}
              <div
                className='globalImageBackground'
                style={{
                  backgroundImage: `url(${`/assets/images/subscribers/Mobile-bro-1.png`})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>

            {!sentCode && (
              <div className='changedVerificationBody'>
                <div className='infoAlertVerify'>
                  <div className='icon'>
                    <MdInfoOutline />
                  </div>
                  <div className='text'>
                    Please provide your phone number so we can contact you if your offer is accepted
                    and shipment is arranged
                  </div>
                </div>
                <div className='VerificationNumberModal'>
                  <DPhoneInput
                    id='VerificationPhoneNumber'
                    label={t("subscribers.details.Your_phone_number")}
                    placeholder={t("subscribers.details.Phone_number")}
                    required
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    indexValue={phoneIndex}
                    setIndexValue={setPhoneIndex}
                    error={
                      sendOtpError?.errors?.phone?.[0] || sendOtpError?.errors?.phone_index_id?.[0]
                    }
                  />
                </div>
              </div>
            )}

            {sentCode && (
              <div className='veriFyCodeBox'>
                <div className='text'>
                  <h2>{t("subscribers.details.Enter_verification_code")}</h2>
                  <p>
                    {t("subscribers.details.Received_verification_code_text")}
                    <span style={{ marginLeft: "2px" }}>
                      +{phoneIndex?.value?.phone_index} {phoneNumber}
                    </span>
                  </p>
                </div>
                <div className='OTPVerifyBox'>
                  <OTP value={code} setValue={setCode} count={4} error={verifyError?.error} />
                </div>
                <div className='SendAgainBox'>
                  <SendAgain
                    title={t("subscribers.details.Send_again")}
                    onClick={() => verifySubmitHandler()}
                    result={sendOtpResult}
                    loading={sendOtpLoading}
                    reset={() => dispatch(resetSendOtp())}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default VerificationModal;
