import React, { forwardRef, useEffect, useState } from "react";
import "./FilterDetailSearchModal.scss";

import { useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import en from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { FaFilter } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { MdLocationSearching, MdLocationOn, MdCalendarToday } from "react-icons/md";

import DOverlay from "../../../../componentsDashboard/Modal/DOverlay/DOverlay";
import DInputs from "../../../../componentsDashboard/Form/DInputs/DInputs";
import DTextButton from "../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import DButton from "../../../../componentsDashboard/Form/DButtons/DButton/DButton";

const FilterDetailSearchModal = ({
  isOpen,
  isClose,
  onClear,
  pickupCountry,
  setPickupCountry,
  dropoffCountry,
  setDropoffCountry,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const { t } = useTranslation();
  const color = "#1A2023";
  const bgColor = "#F4F6F9";

  const { ISO } = useSelector((state) => state.lang);

  const [pickupCountryFilter, setPickupCountryFilter] = useState(pickupCountry);
  const [dropoffCountryFilter, setDropoffCountryFilter] = useState(dropoffCountry);

  const [startDateFilter, setStartDateFilter] = useState(startDate);
  const [endDateFilter, setEndDateFilter] = useState(endDate);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
  };

  const handleFilter = () => {
    setPickupCountry(pickupCountryFilter);
    setDropoffCountry(dropoffCountryFilter);
    setStartDate(startDateFilter);
    setEndDate(endDateFilter);
    isClose();
  };

  const DDatepickerButton = forwardRef(({ value, onClick }, ref) => (
    <div id='DatepickerButton' onClick={onClick} ref={ref}>
      <div className='text'>
        {value ? (
          <p className='valuetext'>{value}</p>
        ) : (
          <p className='placeholder'>Select date range</p>
        )}
      </div>
      <div className='icon'>
        <MdCalendarToday />
      </div>
    </div>
  ));

  useEffect(() => {
    if (ISO === "en") {
      registerLocale("en", en);
    } else if (ISO === "ka") {
      registerLocale("ka", ka);
    } else if (ISO === "ru") {
      registerLocale("ru", ru);
    }
  }, [ISO]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='FilterDetailSearchModal'>
        <div
          className='header'
          style={{
            borderBottom: `2px solid ${color}`,
            backgroundColor: `${bgColor}`,
          }}
        >
          <div className='title'>
            <div className='titleIcon'>
              <FaFilter />
            </div>
            <h2 style={{ color: `${color}` }}>Filters</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='content'>
          <div className='inputGroup'>
            <h3>Custom route</h3>
            <div className='inputsContainer'>
              <DInputs
                icon={<MdLocationSearching />}
                placeholder={"Pickup country"}
                value={pickupCountryFilter}
                setValue={setPickupCountryFilter}
              />
              <DInputs
                icon={<MdLocationOn />}
                placeholder={"Drop off country"}
                value={dropoffCountryFilter}
                setValue={setDropoffCountryFilter}
              />
            </div>
          </div>
          <div></div>
          <div className='inputGroup'>
            <h3>Pick up date range</h3>
            <DatePicker
              selected={startDateFilter}
              onChange={onChange}
              startDate={startDateFilter}
              endDate={endDateFilter}
              selectsRange
              placeholderText='Select date range'
              customInput={<DDatepickerButton />}
            />
          </div>
        </div>
        <div className='footer'>
          <DTextButton
            title={t("Clear")}
            onClick={() => {
              onClear();
              isClose();
            }}
            info
          />
          <DButton title={t("Filter")} onClick={handleFilter} />
        </div>
      </div>
    </DOverlay>
  );
};

export default FilterDetailSearchModal;
