import React, { useEffect, useState } from "react";
import "./SubscribersMain.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSubscribersLoadboardsOrders } from "../../../store/subscribers/subscribersSlice";
import { MdOutlineArrowForward } from "react-icons/md";

import Wrapper from "../../../componentsAdditional/Wrapper";
import MainTitle from "../../../componentsDashboard/MainTitle/MainTitle";
import SubscribersFilters from "../../../componentsSubscribers/SubscribersFilters/SubscribersFilters";
import SubscribersTabSwitcher from "../../../componentsSubscribers/SubscribersTabSwitcher/SubscribersTabSwitcher";
import useQuerySerialization from "../../../helper/useQuerySerialization";
import SubscribersOrdersList from "../../../componentsSubscribers/SubscribersOrdersList/SubscribersOrdersList";
import DButton from "../../../componentsDashboard/Form/DButtons/DButton/DButton";

const SubscribersMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { serializeArray, deserializeArray } = useQuerySerialization();

  const {
    getPreferencesResult,
    getSubscribersLoadboardsOrdersResult,
    getProfileResult,
    getTrailerTypesResult,
    getRoutesResult,
    makeBidResult,
  } = useSelector((state) => state.subscribers);

  const trailersOptions = getTrailerTypesResult?.map((trailerType) => ({
    value: { id: trailerType.id, count: trailerType.orders_count },
    label: trailerType.name,
  }));

  const routesOptions = getRoutesResult?.map((route) => ({
    value: { id: route.id, count: route.orders_count },
    label: route.name,
  }));

  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") ? searchParams.get("tab") : "loads"
  );
  const [preferences, setPreferences] = useState(
    searchParams.get("preferences") === "true" ? true : false
  );
  const [pickupDropoffInGeorgia, setPickupDropoffInGeorgia] = useState(
    searchParams.get("pickupDropoffInGeorgia") === "true" ? true : false
  );
  const [pickupNext3Days, setPickupNext3Days] = useState(
    searchParams.get("pickupNext3Days") === "true" ? true : false
  );
  const routesSearchParamName = "routes";
  const [selectedRoutes, setSelectedRoutes] = useState(
    deserializeArray(searchParams.get(routesSearchParamName)) || []
  );
  const trailerSearchParamName = "trailers";
  const [selectedTrailers, setSelectedTrailers] = useState(
    deserializeArray(searchParams.get(trailerSearchParamName)) || []
  );

  const [pickupCountry, setPickupCountry] = useState("");
  const [dropoffCountry, setDropoffCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [sort, setSort] = useState(searchParams.get("sort") ? searchParams.get("sort") : null);

  const badge = getProfileResult?.bids_count || 0;

  const onClear = () => {
    if (preferences) {
      setPreferences(false);
      searchParams.delete("preferences");
    }
    if (pickupDropoffInGeorgia) {
      setPickupDropoffInGeorgia(false);
      searchParams.delete("pickupDropoffInGeorgia");
    }
    if (pickupNext3Days) {
      setPickupNext3Days(false);
      searchParams.delete("pickupNext3Days");
    }
    if (selectedRoutes?.length > 0) {
      setSelectedRoutes([]);
      searchParams.delete(routesSearchParamName);
    }
    if (selectedTrailers?.length > 0) {
      setSelectedTrailers([]);
      searchParams.delete(trailerSearchParamName);
    }
    if (pickupCountry) {
      setPickupCountry("");
    }
    if (dropoffCountry) {
      setDropoffCountry("");
    }
    if (startDate) {
      setStartDate(null);
    }
    if (endDate) {
      setEndDate(null);
    }
    if (sort) {
      setSort(null);
      searchParams.delete("sort");
    }
    setSearchParams(searchParams);
  };

  const handlePreferenceFilterSelect = () => {
    if (!preferences) {
      onClear();
      setPreferences(true);
      searchParams.set("preferences", true);
      setSearchParams(searchParams);
      if (getPreferencesResult?.routes?.length > 0) {
        const newSelectedRoutes = getPreferencesResult.routes?.map((route) => {
          return {
            value: { id: route.id },
            label: route.name,
          };
        });
        setSelectedRoutes(newSelectedRoutes);
        searchParams.set(routesSearchParamName, serializeArray(newSelectedRoutes || []));
        setSearchParams(searchParams);
      }
      if (getPreferencesResult?.trailer_types?.length > 0) {
        const newSelectedTraierTypes = getPreferencesResult.trailer_types?.map((trailerType) => {
          return {
            value: { id: trailerType.id },
            label: trailerType.name,
          };
        });
        setSelectedTrailers(newSelectedTraierTypes);
        searchParams.set(trailerSearchParamName, serializeArray(newSelectedTraierTypes || []));
        setSearchParams(searchParams);
      }
    }
  };

  const handlePreferenceFilterDeselect = () => {
    if (preferences) {
      setPreferences(false);
      searchParams.delete("preferences");
      setSearchParams(searchParams);
      if (selectedRoutes?.length > 0) {
        setSelectedRoutes([]);
        searchParams.delete(routesSearchParamName);
        setSearchParams(searchParams);
      }
      if (selectedTrailers?.length > 0) {
        setSelectedTrailers([]);
        searchParams.delete(trailerSearchParamName);
        setSearchParams(searchParams);
      }
    }
  };

  useEffect(() => {
    if (preferences) {
      const isRoutesChanged = selectedRoutes?.length !== getPreferencesResult?.routes?.length;
      const isTrailersChanged =
        selectedTrailers?.length !== getPreferencesResult?.trailer_types?.length;
      if (
        pickupDropoffInGeorgia ||
        pickupNext3Days ||
        pickupCountry ||
        dropoffCountry ||
        startDate ||
        endDate ||
        isRoutesChanged ||
        isTrailersChanged
      ) {
        setPreferences(false);
        searchParams.delete("preferences");
        setSearchParams(searchParams);
      }
    }
  }, [
    dropoffCountry,
    endDate,
    pickupCountry,
    pickupDropoffInGeorgia,
    pickupNext3Days,
    preferences,
    startDate,
    searchParams,
    setSearchParams,
    selectedRoutes?.length,
    getPreferencesResult?.routes?.length,
    getPreferencesResult?.trailer_types?.length,
    selectedTrailers?.length,
  ]);

  useEffect(() => {
    if (getPreferencesResult) {
      dispatch(
        getSubscribersLoadboardsOrders({
          signature: token,
          page: page,
          pickup_next_three_days: pickupNext3Days,
          pickup_address: pickupCountry,
          dropoff_address: dropoffCountry,
          pickup_date_max: endDate,
          pickup_date_min: startDate,
          registration_country:
            pickupDropoffInGeorgia && getPreferencesResult?.registrationCountry?.code,
          subscriber_preferences: preferences && 1,
          routes: selectedRoutes?.map((item) => item.value.id),
          trailer_types: selectedTrailers?.map((item) => item.value.id),
          sort_by: sort,
          has_my_bids: activeTab === "bids" ? 1 : 0,
        })
      );
    }
  }, [
    dispatch,
    dropoffCountry,
    endDate,
    getPreferencesResult,
    page,
    pickupCountry,
    pickupDropoffInGeorgia,
    pickupNext3Days,
    preferences,
    selectedRoutes,
    selectedTrailers,
    startDate,
    token,
    sort,
    makeBidResult,
    activeTab,
  ]);

  return (
    <Wrapper documentTitle={t("subscribers.documentTitles.Subscribers")}>
      <section id='SubscribersMain'>
        <div className='wrapper'>
          <div className='signUpSuggestionContainer'>
            <div className='group'>
              <h3>{t("CRM for transporters")}</h3>
              <div className='badge'>Free</div>
            </div>
            <div className='group'>
              <DButton
                title={<MdOutlineArrowForward />}
                onClick={() => {
                  navigate("/register");
                }}
              />
            </div>
          </div>
          <MainTitle title={t("tms.titles.Load Boards")} />
          <SubscribersTabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} badge={badge} />
          <SubscribersFilters
            preferences={preferences}
            setPreferences={setPreferences}
            pickupDropoffInGeorgia={pickupDropoffInGeorgia}
            setPickupDropoffInGeorgia={setPickupDropoffInGeorgia}
            pickupNext3Days={pickupNext3Days}
            setPickupNext3Days={setPickupNext3Days}
            onClear={onClear}
            selectedRoutes={selectedRoutes}
            setSelectedRoutes={setSelectedRoutes}
            routesOptions={routesOptions}
            routesSearchParamName={routesSearchParamName}
            selectedTrailers={selectedTrailers}
            setSelectedTrailers={setSelectedTrailers}
            trailersOptions={trailersOptions}
            trailerSearchParamName={trailerSearchParamName}
            pickupCountry={pickupCountry}
            setPickupCountry={setPickupCountry}
            dropoffCountry={dropoffCountry}
            setDropoffCountry={setDropoffCountry}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handlePreferenceFilterSelect={handlePreferenceFilterSelect}
            handlePreferenceFilterDeselect={handlePreferenceFilterDeselect}
            sort={sort}
            setSort={setSort}
          />
          <SubscribersOrdersList
            page={page}
            setPage={setPage}
            items={getSubscribersLoadboardsOrdersResult?.data}
            total={getSubscribersLoadboardsOrdersResult?.meta?.total}
            pageCount={getSubscribersLoadboardsOrdersResult?.meta?.last_page}
            sort={sort}
            setSort={setSort}
          />
        </div>
      </section>
    </Wrapper>
  );
};

export default SubscribersMain;
